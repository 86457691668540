import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'JaQor',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, 3D DESIGN',
    description: `JaQor in Belize was one of the most challenging and enjoyable creative directing jobs I have had the pleasure of being apart of. Belize was always a location I had wanted to visit so being brought on to lead a diverse team of architects, designers and developers made this challenge to good to pass up. This role had many layers to from initial brand development, site map design, location research and logistics. The JaQor logo represents movement by combining the Birds of Paradise flower and the motion of a wave. The combination of relaxation and constant progression. The first phase of the resort is Je’Lal, a 16 home development. We designed the homes to be modern and built with sustainable practices. With materials being expensive to bring into Belize we had to design with local materials in mind. This project is currently in development and I am excited to see the outcome of a talented group of individuals hard work. `,
  },
  caseId: 'jaqor',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
    'image16.jpg',
    'image17.jpg',
    'image18.jpg',
    'image19.jpg',
    'image20.jpg',
    'image21.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
